







































import { Component, Vue } from 'vue-property-decorator';
import { differenceInDays } from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import RenewPlanLoading from './RenewPlanLoading.vue';
import PaymentForm from './components/PaymentForm/PaymentForm.vue';
import PurchaseSummary from '@/pages/Shopping/components/PurchaseSummary/PurchaseSummary.vue';
import ModalDescriptionsPlan from '../Shopping/components/ModalDescriptionsPlan/ModalDescriptionsPlan.vue';

import Copy from '@/assets/icons/Copy.vue';

import PaymentService from '@/services/Payment/PaymentService';
import PlansService from '@/services/Plans/PlansService';

import ActiveModal from '@/share/Util/ActiveModal';
import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';

import { PaymentSuccess } from '../Shopping/interfaces/IPayment';

import { Plan } from '../Shopping/interfaces/IShopping';

const PAYMENT_STATUS_CREATED = 'created';
const INDEX_TAB_MENU_PROFILE_PLAN = 2;

@Component({
  components: {
    PageHeader,
    Footer,
    RenewPlanLoading,
    FeedbackUser,
    Copy,
    PaymentForm,
    ModalDescriptionsPlan,
    PurchaseSummary
  },
  filters: {
    formattedDate(date: string) {
      if (!date) return '';

      const { day, month, year } = getDataSeparatedFromDateTime(date);

      return `${day}-${month}-${year}`;
    }
  }
})
export default class RenewPlan extends Vue {
  private isLoadingRenewPlan = false;
  private isLoadingPlan = false;

  private paymentData: PaymentSuccess | null = null;
  private plan: Plan | null = null;

  private setModal = new ActiveModal();
  private PaymentService = new PaymentService();
  private PlansService = new PlansService();

  async created() {
    await this.getPendency();
    this.getPlan();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get renewDate() {
    const { day, month, year } = getDataSeparatedFromDateTime(String(new Date()));

    return `${day}-${month}-${year}`;
  }

  get isShowRenewPlan() {
    if (!this.paymentData) return false;

    const expireDate = this.planUser?.expireDate;

    const daysRemaining = differenceInDays(new Date(expireDate), new Date());

    if (daysRemaining > 0) return true;

    return false;
  }

  get planUser() {
    return this.$store.getters.profile.plan;
  }

  async getPlan() {
    try {
      this.isLoadingPlan = true;

      const response = await this.PlansService.getPlan({
        planId: Number(this.planUser.planID)
      });

      if (response) {
        this.plan = response;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o plano.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingPlan = false;
    }
  }

  async getPendency() {
    try {
      this.isLoadingRenewPlan = true;

      const [response] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS_CREATED
      });

      this.paymentData = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingRenewPlan = false;
    }
  }

  handleActivePlan(planActive: Plan) {
    if (!planActive) return;

    this.plan = { ...planActive };
  }

  handleClickModalDescriptive() {
    this.setModal.activeModal('ModalDescriptionsPlan');
  }

  redirectPagePlan() {
    this.$store.commit('setTabProfile', INDEX_TAB_MENU_PROFILE_PLAN);

    return '/profile';
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Plano', to: this.redirectPagePlan() },
      { title: 'Renovação', to: null }
    ]);
  }
}
