









































import { Component, Prop, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import MenuPayment from '@/pages/Shopping/components/MenuPayment/MenuPayment.vue';
import FormCreditCart from '@/pages/Shopping/components/FormCreditCart/FormCreditCart.vue';
import FormPix from '@/pages/Shopping/components/FormPix/FormPix.vue';
import FormBillet from '@/pages/Shopping/components/FormBillet/FormBillet.vue';
import FormAddress from '@/pages/Shopping/components/FormAddress/FormAddress.vue';

import PaymentService from '@/services/Payment/PaymentService';
import { PaymentPostRenewPlan } from '@/services/Payment/paymentInterface';

import {
  INDEX_OPTION_MENU,
  METHOD_PAYMENT,
  METHOD_PAYMENT_TITLE,
  METHOD_BY_CREDIT_CART,
  METHOD_BY_BILLET,
  METHOD_BY_PIX
} from '@/pages/Shopping/constants';

import { Plan } from '@/pages/Shopping/interfaces/IShopping';
import {
  AddressForm,
  BilletForm,
  CreditCartForm,
  PixForm
} from '@/pages/Shopping/interfaces/IForms';

import { PaymentSuccess } from '@/pages/Shopping/interfaces/IPayment';

const VALID_CPF = 11;

@Component({
  components: {
    MenuPayment,
    FormCreditCart,
    FormPix,
    FormBillet,
    FormAddress,
    Loading
  }
})
export default class PaymentForm extends Vue {
  @Prop({ required: true }) plan!: Plan;
  @Prop({ required: true }) payment!: PaymentSuccess;

  private INDEX_OPTION_MENU = INDEX_OPTION_MENU;

  private isLoadingPay = false;

  private creditCart: CreditCartForm = {
    card_number: '',
    holder_name: '',
    card_expiration: '',
    card_cvv: '',
    installments: 1,
    cpf: ''
  };

  private billet: BilletForm = {
    cpf: ''
  };

  private pix: PixForm = {
    cpf: ''
  };

  private address: AddressForm = {
    zipcode: this.payment.paymentAddress.zipcode,
    street: this.payment.paymentAddress.street,
    state: this.payment.paymentAddress.state,
    city: this.payment.paymentAddress.city,
    neighborhood: this.payment.paymentAddress.neighborhood,
    number: this.payment.paymentAddress.number,
    additional_details: this.payment.paymentAddress.additional_details || ''
  };

  private PaymentService = new PaymentService();

  get methods() {
    return METHOD_PAYMENT_TITLE[this.choiceIndexMenu];
  }

  get isValidateForm() {
    return (
      (INDEX_OPTION_MENU.CREDIT_CART === this.choiceIndexMenu && this.isValidateFormCreditCart)
      || (INDEX_OPTION_MENU.BILLET === this.choiceIndexMenu && this.isValidateFormBillet)
      || (INDEX_OPTION_MENU.PIX === this.choiceIndexMenu && this.isValidateFormPix)
    );
  }

  get isValidateFormCreditCart() {
    return !!(
      this.creditCart.card_number
      && this.creditCart.holder_name
      && this.creditCart.card_expiration
      && this.creditCart.card_cvv
      && this.creditCart.installments
      && this.creditCart.cpf
      && this.creditCart.cpf.length === VALID_CPF
    );
  }

  get isValidateFormBillet() {
    return !!this.billet.cpf && this.billet.cpf.length === VALID_CPF;
  }

  get isValidateFormPix() {
    return !!this.pix.cpf && this.pix.cpf.length === VALID_CPF;
  }

  get isValidateFormAddress() {
    return !!(
      this.address.zipcode
      && this.address.street
      && this.address.state
      && this.address.city
      && this.address.neighborhood
      && this.address.number
    );
  }

  get choiceIndexMenu() {
    return this.$store.getters.choiceIndexMenu;
  }

  async handlePayment() {
    try {
      this.isLoadingPay = true;

      const data: PaymentPostRenewPlan = this.paymentData();

      const response = await this.PaymentService.postPaymentPlan(data);

      if (response.paymentIds.length) {
        this.redirectPayment(response.paymentIds[0]);

        this.clearForms();

        this.$store.commit('setRenewPlanPayment', null);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao comprar o plano.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingPay = false;
    }
  }

  paymentData() {
    const defaultData: PaymentPostRenewPlan = {
      planID: Number(this.plan.planId),
      amount: Number(this.payment.payment.amount.toFixed(2)),
      discountCode: '',
      paymentMethodCode: METHOD_PAYMENT[this.choiceIndexMenu],
      installments: 1,
      paymentID: this.payment.payment.ID,
      paymentAddress: {
        ...this.address,
        country: 'BR'
      },
      paymentProfile: {
        registry_code: this.pix.cpf
      }
    };

    let data: PaymentPostRenewPlan = defaultData;

    switch (METHOD_PAYMENT[this.choiceIndexMenu]) {
      case METHOD_BY_CREDIT_CART:
        data = {
          ...defaultData,
          installments: Number(this.creditCart.installments),
          paymentProfile: {
            ...this.creditCart,
            registry_code: this.creditCart.cpf
          }
        };

        break;

      case METHOD_BY_PIX:
        data = defaultData;
        break;

      case METHOD_BY_BILLET:
        data = {
          ...defaultData,
          paymentProfile: {
            registry_code: this.billet.cpf
          }
        };
        break;

      default:
        break;
    }

    return data;
  }

  redirectPayment(idPurchase: string) {
    let name = '';

    if (METHOD_PAYMENT[this.choiceIndexMenu] === METHOD_BY_PIX) name = 'RenewPaymentPix';
    if (METHOD_PAYMENT[this.choiceIndexMenu] === METHOD_BY_BILLET) name = 'RenewPaymentBillet';
    if (METHOD_PAYMENT[this.choiceIndexMenu] === METHOD_BY_CREDIT_CART) name = 'RenewPaymentThankYou';

    this.$router.push({
      name,
      params: {
        idPurchase
      }
    });
  }

  clearForms() {
    this.clearFormCreditCart();
    this.clearFormBillet();
    this.clearFormPix();
    this.clearFormAddress();
  }

  clearFormCreditCart() {
    this.creditCart.card_number = '';
    this.creditCart.holder_name = '';
    this.creditCart.card_expiration = '';
    this.creditCart.card_cvv = '';
    this.creditCart.installments = 1;
    this.creditCart.cpf = '';
  }

  clearFormBillet() {
    this.billet.cpf = '';
  }

  clearFormPix() {
    this.pix.cpf = '';
  }

  clearFormAddress() {
    this.address.zipcode = '';
    this.address.street = '';
    this.address.state = '';
    this.address.city = '';
    this.address.neighborhood = '';
    this.address.number = '';
    this.address.additional_details = '';
  }
}
